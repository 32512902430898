<template>
    <header>
        <v-app-bar app color="primary" dark id="RenaissHeader">
            <v-toolbar-title>{{ headerText }}</v-toolbar-title>
        </v-app-bar>
    </header>
</template>

<script>
export default {
    props: {
        headerText: {
            type: String,
        },
    }
}
</script>

<style lang="scss" scoped>

/* タイトルの幅調整 */
.v-toolbar__title {
    overflow: visible !important;
    margin-right: 50px !important;
    font-size: 80%;

    @media only screen and (min-width:651px) {
        font-size: 100%;
    }
 
    @media only screen and (min-width:961px) {
        font-size: 110%;
    }
}
</style>